@import "../global/global_style";


body {
  color: $primary;
  font-family: 'Roboto', sans-serif;

 .dropdown-toggle::after {
    display:none;
  }
  .btn-user.show a ,  .btn-user:hover a {
    color:white;
  }

  .user-account:active {
 //   color: white !important;
    background-color: var(--bs-dropdown-link-hover-bg) !important;
  }
  .user-logout:active {
    background-color: var(--bs-dropdown-link-hover-bg) !important;
  }

}

.search-container {
  position: relative;
}

.clear-button {
  position: absolute;
  right: 3px;
  top: 50%;
  transform: translateY(-50%);
  color: $primary;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.accordion-button:focus {
  outline: none;
  box-shadow: none; /* Pour supprimer également l'ombre de focus si elle est présente */
}

.dropdown-toggle{
  max-width: 150px; /* Ajustez cette valeur selon vos besoins */
//  overflow-y: auto; /* Active le défilement vertical si nécessaire */
}
.dropdown-toggle {
  max-width: 200px;
}

.text-marron {
  color: #65524D;
}

.aside-title, .post-title {
  font-family: 'Acme', sans-serif;
}

.aside-category {
  font-family: 'Acme', sans-serif;
}

.category-title, .post-title {
  font-family: 'Acme', sans-serif;
}
.nav-category-title {
//  font-family: 'Rubik', sans-serif;
  font-family: 'Roboto Mono', monospace;
}
.offcanvas {

  width: 100%;
  border-left : none !important;

  ul {
    border-radius: 0;
  }
  .dropdown-menu {
    background-color: $primary;
  }

  @media screen and (min-width:768px) {

    width: 450px;

  }
  @media screen and (max-width:992px) {

    .navbar-nav  .dropdown-item {
      white-space: break-spaces;
    }

  }

}

