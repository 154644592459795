@import "global";

@import '../../../public/prism/prism.css';
@import '../../../public/prism/prism-vsc-dark-plus.css';


body {
    background-image:url('../../../public/images/assets/base_background.jpg');
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    display:flex;
    flex-direction: column;
}

label.required:after {
    content: "*";
}

.btn-outline-success:hover, .btn-outline-info:hover, .btn-outline-info.show:hover, .btn-outline-info.show:active {
    color: white;
}
.sort .dropdown-item {
    color: $primary;
}
.sort .dropdown-item:hover {
    color: $primary;
}
.sort .dropdown-item:active , .dropdown-item:active a {
    color: white;
}
a.sortable, a.asc, a.desc {
    text-decoration: none;
}
.fa-arrow-down-short-wide:before {
    color: $primary;
}
.text-gray {
    color: #27618f;
}
